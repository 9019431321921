import React, { Fragment } from "react"

/* Import Global Components */
import LoadingOverlay from "~components/loadingOverlay"

/* Fonts */
import "~assets/fonts/scto-grotesk-b/stylesheet.css"

/* Import Global Normalize */
import "normalize.css"

/* Import Global Reset */
import "~styles/reset.css"

/* Import Global CSS Variables */
import "~styles/variables.css"

/* Import Global Styles */
import "~styles/app.css"

/* Import Local Styles */
import "./layouts.css"

const Layout = ({ children }) => {
  return (
    <Fragment>
      <LoadingOverlay />
      <main>{children}</main>
    </Fragment>
  )
}

export default Layout
