import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import classnames from "classnames"

import "./loading-overlay.css"

const LoadingOverlay = () => {
  const {
    sanitySettings: {
      backgroundColor: { hex: backgroundColor },
      linksColor: { hex: linksColor },
    },
  } = useStaticQuery(graphql`
    query LoadingOverlayQuery {
      sanitySettings(_id: { eq: "settings" }) {
        backgroundColor {
          hex
        }
        linksColor {
          hex
        }
      }
    }
  `)

  const [isLoaded, setIsLoaded] = useState(false)
  const [isBackgroundColorSet, setIsBackgroundColorSet] = useState(false)
  const [isTextColorSet, setIsTextColorSet] = useState(false)

  useEffect(() => {
    if (backgroundColor && document) {
      document.documentElement.style.setProperty(
        "--color-background",
        backgroundColor
      )
      setIsBackgroundColorSet(true)
    }
  }, [backgroundColor])

  useEffect(() => {
    if (linksColor && document) {
      document.documentElement.style.setProperty("--color-gray", linksColor)
      setIsTextColorSet(true)
    }
  }, [linksColor])

  useEffect(() => {
    if ((window && isTextColorSet, isBackgroundColorSet)) {
      window.setTimeout(() => {
        setIsLoaded(true)
      }, 10)
    }
  }, [isTextColorSet, isBackgroundColorSet])

  return (
    <div
      className={classnames("loading-overlay", { ["is-loaded"]: isLoaded })}
    />
  )
}

export default LoadingOverlay
